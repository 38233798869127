import React, { useState } from "react";
import { CustomButton } from "./custom-button";
import { FinxaiDataGroup } from "../models/data-group";

interface GroupCreationFormProps {
  optionNames: string[];
  onSubmit?: (groupData: FinxaiDataGroup) => void;
}

export const GroupCreationForm: React.FC<GroupCreationFormProps> = ({
  optionNames,
  onSubmit,
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [groupName, setGroupName] = useState<string>("");

  const handleOptionClick = (option: string) => {
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.includes(option)
        ? prevSelectedOptions.filter(
            (selectedOption) => selectedOption !== option
          )
        : [...prevSelectedOptions, option]
    );
  };

  const handleSubmit = () => {
    if (groupName) {
      const groupData: FinxaiDataGroup = {
        groupName,
        tickers: selectedOptions,
      };
      if (onSubmit) {
        onSubmit(groupData);
      }
    }
  };

  return (
    <div className="p-4 ">
      <input
        type="text"
        value={groupName}
        onChange={(e) => setGroupName(e.target.value)}
        placeholder="Group Name"
        className="placeholder:text-gray-600 text-white bg-primaryTableBg p-2 rounded-lg mb-4 w-full shadow-xl caret-white"
      />
      {/* <div className="mb-4">
        {optionNames.map((option, index) => (
          <button
            key={index}
            onClick={() => handleOptionClick(option)}
            style={{
              transition: "border 0.3s ease-in-out, transform 0.3s ease-in-out",
            }}
            className={`p-2 m-1 text-white ${
              selectedOptions.includes(option)
                ? "border-purple-600 font-bold"
                : "border-transparent"
            } rounded-lg border-2`}
          >
            {option}
          </button>
        ))}
      </div> */}
      {groupName && (
        <div className="flex justify-center items-center">
          <CustomButton
            label="Create Group"
            onClick={handleSubmit}
            className="p-2 text-white rounded-lg shadow-xl font-bold"
          ></CustomButton>
        </div>
      )}
    </div>
  );
};

export default GroupCreationForm;
