import React from "react";
import { FinxAiLogoWithText } from "./finxai-logo-with-text";
import WebSocketConnectionStatusIndicator from "./web-socket-connection-indicator";

export const FinxAiFooter = () => {
  return (
    <div className="fixed bottom-0 left-0 right-0 flex flex-row justify-end items-center p-2 bg-secondary">
      <div className="flex flex-row items-center">
        <div className="mx-4">
          <WebSocketConnectionStatusIndicator />
        </div>
        <span className="text-white">Powered by</span>
        <div className="scale-90">
          <FinxAiLogoWithText />
        </div>
      </div>
    </div>
  );
};
