import React, { useState, useEffect } from "react";
import useWebSocket from "react-use-websocket";
import logger from "../utils/logger";

const WebSocketTester: React.FC = () => {
  const [messages, setMessages] = useState<string[]>([]);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  const { sendMessage, lastMessage, readyState, getWebSocket } = useWebSocket(
    "ws://localhost:3001", // Dummy WebSocket server
    {
      onOpen: () => {
        logger.debug("WebSocket connected");
        setIsConnected(true);
      },
      onClose: () => {
        logger.debug("WebSocket disconnected");
        setIsConnected(false);
      },
      shouldReconnect: (closeEvent) => true, // Automatically reconnect
    }
  );

  useEffect(() => {
    if (lastMessage !== null) {
      logger.debug("Message received:", lastMessage.data);
      setMessages((prevMessages) => [...prevMessages, lastMessage.data]);
    }
  }, [lastMessage]);

  const disconnect = () => {
    const socket = getWebSocket();
    if (socket) {
      socket.close();
    }
  };

  return (
    <div className="bg-white">
      <h1>WebSocket Tester</h1>
      <button onClick={disconnect} disabled={!isConnected}>
        Disconnect
      </button>
      <div>
        <h2>Messages:</h2>
        <ul>
          {messages.map((message, index) => (
            <li key={index}>{message}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default WebSocketTester;
