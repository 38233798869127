import FinxAiLogoSvg from "../assets/logos/finxai_logo_svg_only.svg";

interface FinxAiLogoWithTextProps {
  disableColor?: boolean;
  disableText?: boolean;
}

export const FinxAiLogoWithText: React.FC<FinxAiLogoWithTextProps> = ({
  disableColor = false,
  disableText = false,
}) => {
  return (
    <div className="flex items-center opacity-50">
      <img
        src={FinxAiLogoSvg}
        alt="FinxAi Logo"
        className={`h-12 mr-2 ${disableColor ? "filter grayscale" : ""}`}
      />
      <span className="font-quantify text-3xl dark:text-white">FinXAi</span>
    </div>
  );
};
export const DynamicLogoWithText: React.FC<FinxAiLogoWithTextProps> = ({
  disableColor = false,
  disableText = false,
}) => {
  const logoPath = process.env.REACT_APP_LOGO_PATH || "";
  const logoText = process.env.REACT_APP_LOGO_TEXT || "FinxAi";

  return (
    <div className="flex items-center">
      {logoPath && (
        <img
          src={logoPath}
          alt="FinxAi Logo"
          className={`h-12 mr-2 ${disableColor ? "filter grayscale" : ""}`}
        />
      )}
      {!disableText && logoText && (
        <span
          className={`text-3xl dark:text-white ${
            logoText ? "" : "font-quantify"
          }`}
        >
          {logoText}
        </span>
      )}
    </div>
  );
};
