import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, AppDispatch } from "../store/store";
import {
  setColumns,
  setData,
  setGroupIndex,
  setSearchInput,
  setSearchResults,
  setChangedTradeCells,
  setShowAnimation,
  setModalOpen,
  setIsHovering,
  setColumnLayout,
  addGroup,
  swapColumns,
  addTickerToGroup,
  clearChangedCells,
  setChangedMarketDataCells,
  setSortColumn,
  sortData,
  initializeSortLayout,
} from "../store/slices/dataTableSlice";
import CustomPopUpModal from "./custom-popup-modal";
import GroupCreationForm from "./group-creation-form";
import { Fab, SpeedDial, SpeedDialAction, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { MoreVertRounded, SaveRounded } from "@mui/icons-material";
import { FinxaiTrade } from "../models/finxaiTrade";
import { FinxaiDataGroup } from "../models/data-group";
import { FinxaiMktData } from "../models/finxaiMktData";
import {
  setGroups,
  sortMarketData,
  rearrangeTradeOrders,
  sortTradeOrders,
  initializeDataSort,
} from "../store/slices/dataSlice";
import CustomSpeedDial from "./custom-speed-dial";
import { getCache } from "../utils/cache";
import QuantityCell from "./quantity-cell";
import { getWebSocketInstance } from "../store/slices/userSlice";
import { OrderStatus } from "../models/protobuff-objects/finxai_web_pb";
import logger from "../utils/logger";

export interface Column {
  name: string;
  selector: (row: any) => React.ReactNode;
  conditionalCellStyles?: {
    when: (row: any) => boolean;
    style: React.CSSProperties;
  }[];
}

interface Props {
  onRowTap?: (rowData: any) => void;
  columns: Column[];
  data: any[];
  enableGrouping?: boolean;
  username?: string;
  uid?: string; /// Used for creation of groups based on this Uid
  /// eg: for trade tables it is "order_id" and for mkt_data table it is "security_id"
}

const CustomDataTable: React.FC<Props> = ({
  columns,
  data,
  username,
  enableGrouping,
  uid,
  onRowTap,
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    columnsState,
    dataState,
    isHovering,
    sortDirection,
    modalOpen,
    currentColumnLayout,
    groupIndex,
    groups,
    searchInput,
    searchResults,
    changedTradeCells,
    changedMarketDataCells,
    newDataIds,
    sortColIndex,
  } = useSelector((state: RootState) => state.dataTable);

  const { tradeOrders, marketData } = useSelector(
    (state: RootState) => state.data
  );
  const { currentTableIndex } = useSelector((state: RootState) => state.ui);
  const prevDataState = useRef<FinxaiTrade[]>([]);
  const currIndex = useRef<number>(0);
  const nIndex = useRef<number>(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedRow, setSelectedRow] = useState<number | null>(null);

  const handleRowTap = (row: any, rowIndex: number) => {
    const isSelected = selectedRow === rowIndex;
    setSelectedRow(isSelected ? null : rowIndex);
    if (onRowTap && !isSelected) {
      onRowTap(row);
    }
  };
  function isFinxaiMktData(item: any): item is FinxaiMktData {
    return (item as FinxaiMktData).last_traded_px !== undefined;
  }

  function isFinxaiTrade(item: any): item is FinxaiTrade {
    return (item as FinxaiTrade).order_id !== undefined;
  }

  useEffect(() => {
    /// Table layout cache check
    const cacheKey = `${username}_tableLayout_${currentTableIndex}`;
    const cachedColumnsLayout = getCache(cacheKey) as Column[] | null;

    let columnsToUse: Column[];

    if (currentColumnLayout[currentTableIndex]) {
      logger.debug("Rearranged columns exist in memory for", currentTableIndex);
      columnsToUse = currentColumnLayout[currentTableIndex];
    } else if (cachedColumnsLayout) {
      logger.debug("Rearranged columns exist in cache:", cachedColumnsLayout);
      // Merge cached layout with the original columns to restore selectors
      columnsToUse = cachedColumnsLayout.map((cachedColumn: Column) => {
        const originalColumn = columns.find(
          (col) => col.name === cachedColumn.name
        );
        return {
          ...cachedColumn,
          selector: originalColumn?.selector || cachedColumn.selector,
          conditionalCellStyles:
            originalColumn?.conditionalCellStyles ||
            cachedColumn.conditionalCellStyles,
        };
      });
      logger.debug(
        "Setting cached column layout with selectors:",
        columnsToUse
      );
    } else {
      // Default column order if no cache found
      columnsToUse = columns;
    }

    // Set the columns in the state
    dispatch(setColumns(columnsToUse));

    // Set the rearranged data
    dispatch(setData(data));

    dispatch(
      initializeSortLayout({
        username: username!,
        tableIndex: currentTableIndex,
      })
    );
    /// Get cached index
    const cacheColumnNameKey = `${username}_sortLayoutColumnName_${currentTableIndex}`;

    // if (sortColIndex != null) {
    //   console.log(
    //     "Setting internal data sort with cached index: ",
    //     sortColIndex
    //   );

    //   const field = getSelectorField(columnsState[sortColIndex].selector);

    //   dispatch(initializeDataSort({ field: field!, order: sortDirection }));
    // }
  }, [
    currentTableIndex,
    username,
    columns,
    currentColumnLayout,
    dispatch,
    data,
  ]);

  /// Changed value cells
  useEffect(() => {
    const changedTradeEntries: { [key: string]: Set<string> } = {};
    const changedMarketEntries: { [key: string]: Set<string> } = {};

    dataState.forEach((newData) => {
      const prevData = prevDataState.current.find((item) => {
        if (isFinxaiMktData(item)) {
          return item.security_id === newData.security_id;
        } else if (isFinxaiTrade(item)) {
          return item.order_id === newData.order_id;
        }
        return false;
      });

      if (prevData) {
        const changedFields = new Set<string>();
        Object.keys(newData).forEach((key) => {
          if (newData[key] !== prevData[key]) {
            changedFields.add(key);
          }
        });

        if (changedFields.size > 0) {
          if (isFinxaiMktData(newData)) {
            changedMarketEntries[newData.security_id] = changedFields;
          } else if (isFinxaiTrade(newData)) {
            changedTradeEntries[newData.order_id] = changedFields;
          }
        }
      }
    });

    if (Object.keys(changedTradeEntries).length > 0) {
      logger.debug("Updating trade entries: Component view");
      dispatch(setChangedTradeCells(changedTradeEntries));
    }

    if (Object.keys(changedMarketEntries).length > 0) {
      logger.debug("Updating Market Data entries: Component view");

      dispatch(setChangedMarketDataCells(changedMarketEntries));
    }

    const timer = setTimeout(() => {
      dispatch(setShowAnimation(false));
      dispatch(clearChangedCells());
    }, 3000);

    prevDataState.current = dataState;

    return () => clearTimeout(timer);
  }, [dataState, dispatch]);

  function handleGroupCreation(group: FinxaiDataGroup) {
    dispatch(addGroup(group));
    dispatch(setModalOpen(false));
  }

  const getFilteredData = () => {
    // Filter data only if table index is 0
    let filteredData = dataState;
    if (currentTableIndex === 0) {
      const displayIdColumn = columnsState.find(
        (col) => col.name === "Instrument"
      );

      if (displayIdColumn) {
        filteredData = filteredData.filter(
          (row) => displayIdColumn.selector(row) !== "N.A"
        );
      }
    }

    // If the group has "alldata" or if current table index is not 0, return all filtered data
    if (
      groups[groupIndex].tickers.includes("alldata") ||
      currentTableIndex !== 0
    ) {
      return filteredData;
    }

    // If no tickers are selected, return an empty object with the column names
    if (groups[groupIndex].tickers.length === 0) {
      return [];
    }

    // Otherwise, filter by the selected tickers
    return filteredData.filter((row) =>
      groups[groupIndex].tickers.includes(uid!)
    );
  };

  const handleSort = (index: number) => {
    /// Rendered data update
    dispatch(
      sortData({
        columnIndex: index,
        tableIndex: currentTableIndex,
        username: username,
      })
    );
    /// Actual data update
    const field = getSelectorField(columnsState[index].selector);
    logger.verbose("The field name to be sorted is: ", field);

    const sortDir = sortDirection;

    if (currentTableIndex == 0) {
      dispatch(sortTradeOrders({ field: field!, order: sortDir }));
    } else {
      // dispatch(sortMarketData({field: field!, order: sortDir }))
    }
  };

  const handleColumnReorder = (currentIndex: number, newIndex: number) => {
    const tableIndex = currentTableIndex;
    dispatch(swapColumns({ tableIndex, currentIndex, newIndex, username }));

    logger.debug("Columns are: ");
    logger.debug(columnsState.toLocaleString());
  };

  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    dispatch(setSearchInput(value));

    if (value.length >= 3) {
      const results = dataState.filter((item) =>
        item[uid!].toLowerCase().includes(value.toLowerCase())
      );
      dispatch(setSearchResults(results));
    } else {
      dispatch(setSearchResults([]));
    }
  };

  const handleTickerSelect = (ticker: string) => {
    setSearchTerm(ticker);
    dispatch(setSearchInput(ticker));
    dispatch(setSearchResults([]));
    dispatch(addTickerToGroup({ groupIndex, ticker }));
  };

  const applyConditionalStyles = (
    row: any,
    styles: Column["conditionalCellStyles"]
  ) => {
    const matchedStyle = styles?.find((style) => style.when(row));
    return matchedStyle ? matchedStyle.style : {};
  };

  const getSelectorField = (selector: Function) => {
    const match = selector.toString().match(/row\.(\w+)/);
    return match ? match[1] : null;
  };
  const ws = getWebSocketInstance();

  return (
    <div className="datatable bg-primaryBg flex flex-col">
      <div className="flex flex-row rounded-t-2xl bg-tableHeaderBg text-gray-400">
        {enableGrouping &&
          groups.length > 1 &&
          groups.map((e, index) => (
            <button
              key={e.groupName + index}
              onClick={() => dispatch(setGroupIndex(index))}
              className={`${
                index == groupIndex
                  ? "shadow-2xl shadow-white bg-primary text-white font-bold"
                  : ""
              } rounded-t-2xl px-6 py-2 border-r border-gray-600`}
            >
              {`${groups[index].groupName}`}
            </button>
          ))}
        {groups[groupIndex].tickers.length > 1 &&
          groups[groupIndex].groupName !== "alldata" && (
            <TextField
              label={`Search by ${uid}`}
              value={searchTerm}
              onChange={handleSearchInputChange}
              variant="outlined"
              size="small"
              fullWidth
              sx={{ margin: "0 16px" }}
            />
          )}
      </div>

      <table
        className={`shadow-md border-b overflow-hidden ${
          groups.length == 1 ? "rounded-2xl" : ""
        }`}
      >
        <thead className="bg-tableHeaderBg text-white text-base rounded-2xl shadow-2xl">
          <tr>
            {columnsState.map((column, index) => (
              <th
                className="text-start px-6 py-4 border-gray-700"
                key={index}
                onClick={() => handleSort(index)}
              >
                <div
                  key={column.name + index}
                  draggable
                  onDragStart={() => (currIndex.current = index)}
                  onDragEnter={() => (nIndex.current = index)}
                  onDragEnd={() =>
                    handleColumnReorder(currIndex.current, nIndex.current)
                  }
                  onDragOver={(e) => e.preventDefault()}
                >
                  {column.name}
                  {/* {isHovering === index && (
                    <span>{sortDirection === "asc" ? " 🡡" : " 🡫"}</span>
                  )} */}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {getFilteredData().map((row, rowIndex) => (
            <tr
              key={row.order_id}
              onClick={() => handleRowTap(row, rowIndex)}
              className={`border-collapse cursor-pointer ${
                rowIndex !== columnsState.length - 1 ? "border-b" : ""
              } ${
                onRowTap != null && selectedRow === rowIndex
                  ? "bg-primary"
                  : rowIndex % 2 === 0
                  ? "bg-primaryTableBgLighter"
                  : "bg-primaryTableBg"
              }`}
            >
              {columnsState.map((column, colIndex) => {
                const isMktData = isFinxaiMktData(row);
                const cellKey = isMktData ? row.security_id : row.order_id;
                const changedFields = isMktData
                  ? changedMarketDataCells
                  : changedTradeCells;

                if (currentTableIndex === 0 && column.name === "Filled/Size") {
                  return (
                    <td
                      key={colIndex}
                      className="border-gray-800 text-sm p-2 text-white flex items-center justify-center"
                    >
                      <QuantityCell
                        quantity={Number(row.order_qty)}
                        filledQuantity={Number(row.filled_qty)}
                      />
                    </td>
                  );
                }

                if (currentTableIndex === 0 && column.name === "Side") {
                  return (
                    <td
                      key={colIndex}
                      className="border-gray-800 text-sm text-white flex items-center justify-center"
                    >
                      <div
                        className={`
                          inline-block px-2 py-1 rounded-full
                          ${
                            row.side === "BUY" ? "bg-green-600" : "bg-red-600"
                          } font-bold`}
                        style={{
                          margin: "auto",
                          display: "inline-block",
                        }}
                      >
                        {row.side}
                      </div>
                    </td>
                  );
                }
                return (
                  <td
                    key={colIndex}
                    className={`border-gray-800 text-sm p-2 text-white ${
                      colIndex !== columnsState.length - 1 ? "border-r" : ""
                    } ${
                      changedFields[cellKey]?.has(
                        getSelectorField(column.selector)!
                      )
                        ? "animate-pulse bg-green-600"
                        : ""
                    }`}
                    style={
                      column.conditionalCellStyles
                        ? applyConditionalStyles(
                            row,
                            column.conditionalCellStyles
                          )
                        : {}
                    }
                  >
                    {column.name === "Order Status" ? (
                      <div
                        className={`
                          inline-block px-2  rounded-full
                          ${
                            _handleStatusBasedOnQuantity(
                              Number(row.order_qty),
                              Number(row.filled_qty)
                            ) === "Filled"
                              ? "bg-green-600 font-bold"
                              : "bg-gray-500"
                          }`}
                        style={{
                          margin: "auto", // centers the pill vertically
                          display: "inline-block", // keeps it as a pill shape
                        }}
                      >
                        {_handleStatusBasedOnQuantity(
                          Number(row.order_qty),
                          Number(row.filled_qty)
                        )}
                      </div>
                    ) : (
                      column.selector(row)
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

function _handleStatusBasedOnQuantity(qty: number, filledQty: number): string {
  let status: string = "";

  switch (true) {
    case filledQty === 0:
      status = "Working";
      break;
    case filledQty > 0 && filledQty < qty:
      status = "Partially Filled";
      break;
    case filledQty === qty:
      status = "Filled";
      break;
    case filledQty > qty:
      status = "Overfilled";
      break;
    default:
      status = "Unknown";
  }

  return status;
}

export default CustomDataTable;
