// source: finxai_web.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() {
  if (this) { return this; }
  if (typeof window !== 'undefined') { return window; }
  if (typeof global !== 'undefined') { return global; }
  if (typeof self !== 'undefined') { return self; }
  return Function('return this')();
}.call(null));

goog.exportSymbol('proto.finxai_web.OrderStatus', null, global);
goog.exportSymbol('proto.finxai_web.Side', null, global);
goog.exportSymbol('proto.finxai_web.finxai_instrument', null, global);
goog.exportSymbol('proto.finxai_web.finxai_instrument_leg', null, global);
goog.exportSymbol('proto.finxai_web.finxai_mkt_data', null, global);
goog.exportSymbol('proto.finxai_web.finxai_strategy', null, global);
goog.exportSymbol('proto.finxai_web.finxai_trade', null, global);
goog.exportSymbol('proto.finxai_web.finxai_void', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.finxai_web.finxai_void = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.finxai_web.finxai_void, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.finxai_web.finxai_void.displayName = 'proto.finxai_web.finxai_void';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.finxai_web.finxai_trade = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.finxai_web.finxai_trade, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.finxai_web.finxai_trade.displayName = 'proto.finxai_web.finxai_trade';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.finxai_web.finxai_mkt_data = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.finxai_web.finxai_mkt_data, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.finxai_web.finxai_mkt_data.displayName = 'proto.finxai_web.finxai_mkt_data';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.finxai_web.finxai_instrument_leg = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.finxai_web.finxai_instrument_leg, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.finxai_web.finxai_instrument_leg.displayName = 'proto.finxai_web.finxai_instrument_leg';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.finxai_web.finxai_instrument = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.finxai_web.finxai_instrument, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.finxai_web.finxai_instrument.displayName = 'proto.finxai_web.finxai_instrument';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.finxai_web.finxai_strategy = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.finxai_web.finxai_strategy.repeatedFields_, null);
};
goog.inherits(proto.finxai_web.finxai_strategy, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.finxai_web.finxai_strategy.displayName = 'proto.finxai_web.finxai_strategy';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.finxai_web.finxai_void.prototype.toObject = function(opt_includeInstance) {
  return proto.finxai_web.finxai_void.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.finxai_web.finxai_void} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.finxai_web.finxai_void.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.finxai_web.finxai_void}
 */
proto.finxai_web.finxai_void.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.finxai_web.finxai_void;
  return proto.finxai_web.finxai_void.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.finxai_web.finxai_void} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.finxai_web.finxai_void}
 */
proto.finxai_web.finxai_void.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.finxai_web.finxai_void.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.finxai_web.finxai_void.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.finxai_web.finxai_void} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.finxai_web.finxai_void.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.finxai_web.finxai_trade.prototype.toObject = function(opt_includeInstance) {
  return proto.finxai_web.finxai_trade.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.finxai_web.finxai_trade} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.finxai_web.finxai_trade.toObject = function(includeInstance, msg) {
  var f, obj = {
    orderId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    parentOrdId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    securityId: jspb.Message.getFieldWithDefault(msg, 3, ""),
    tickerSymbol: jspb.Message.getFieldWithDefault(msg, 4, ""),
    ibbgTickerSymbol: jspb.Message.getFieldWithDefault(msg, 5, ""),
    side: jspb.Message.getFieldWithDefault(msg, 6, 0),
    price: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0),
    avgFillPx: jspb.Message.getFloatingPointFieldWithDefault(msg, 8, 0.0),
    orderQty: jspb.Message.getFloatingPointFieldWithDefault(msg, 9, 0.0),
    filledQty: jspb.Message.getFloatingPointFieldWithDefault(msg, 10, 0.0),
    account: jspb.Message.getFieldWithDefault(msg, 11, ""),
    orderStatus: jspb.Message.getFieldWithDefault(msg, 12, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.finxai_web.finxai_trade}
 */
proto.finxai_web.finxai_trade.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.finxai_web.finxai_trade;
  return proto.finxai_web.finxai_trade.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.finxai_web.finxai_trade} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.finxai_web.finxai_trade}
 */
proto.finxai_web.finxai_trade.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setOrderId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setParentOrdId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurityId(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setTickerSymbol(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIbbgTickerSymbol(value);
      break;
    case 6:
      var value = /** @type {!proto.finxai_web.Side} */ (reader.readEnum());
      msg.setSide(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPrice(value);
      break;
    case 8:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setAvgFillPx(value);
      break;
    case 9:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOrderQty(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setFilledQty(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    case 12:
      var value = /** @type {!proto.finxai_web.OrderStatus} */ (reader.readEnum());
      msg.setOrderStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.finxai_web.finxai_trade.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.finxai_web.finxai_trade.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.finxai_web.finxai_trade} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.finxai_web.finxai_trade.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getOrderId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getParentOrdId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getSecurityId();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getTickerSymbol();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getIbbgTickerSymbol();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getSide();
  if (f !== 0.0) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getPrice();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
  f = message.getAvgFillPx();
  if (f !== 0.0) {
    writer.writeDouble(
      8,
      f
    );
  }
  f = message.getOrderQty();
  if (f !== 0.0) {
    writer.writeDouble(
      9,
      f
    );
  }
  f = message.getFilledQty();
  if (f !== 0.0) {
    writer.writeDouble(
      10,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getOrderStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      12,
      f
    );
  }
};


/**
 * optional string order_id = 1;
 * @return {string}
 */
proto.finxai_web.finxai_trade.prototype.getOrderId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_trade} returns this
 */
proto.finxai_web.finxai_trade.prototype.setOrderId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string parent_ord_id = 2;
 * @return {string}
 */
proto.finxai_web.finxai_trade.prototype.getParentOrdId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_trade} returns this
 */
proto.finxai_web.finxai_trade.prototype.setParentOrdId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string security_id = 3;
 * @return {string}
 */
proto.finxai_web.finxai_trade.prototype.getSecurityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_trade} returns this
 */
proto.finxai_web.finxai_trade.prototype.setSecurityId = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string ticker_symbol = 4;
 * @return {string}
 */
proto.finxai_web.finxai_trade.prototype.getTickerSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_trade} returns this
 */
proto.finxai_web.finxai_trade.prototype.setTickerSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string ibbg_ticker_symbol = 5;
 * @return {string}
 */
proto.finxai_web.finxai_trade.prototype.getIbbgTickerSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_trade} returns this
 */
proto.finxai_web.finxai_trade.prototype.setIbbgTickerSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional Side side = 6;
 * @return {!proto.finxai_web.Side}
 */
proto.finxai_web.finxai_trade.prototype.getSide = function() {
  return /** @type {!proto.finxai_web.Side} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.finxai_web.Side} value
 * @return {!proto.finxai_web.finxai_trade} returns this
 */
proto.finxai_web.finxai_trade.prototype.setSide = function(value) {
  return jspb.Message.setProto3EnumField(this, 6, value);
};


/**
 * optional double price = 7;
 * @return {number}
 */
proto.finxai_web.finxai_trade.prototype.getPrice = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.finxai_web.finxai_trade} returns this
 */
proto.finxai_web.finxai_trade.prototype.setPrice = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};


/**
 * optional double avg_fill_px = 8;
 * @return {number}
 */
proto.finxai_web.finxai_trade.prototype.getAvgFillPx = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 8, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.finxai_web.finxai_trade} returns this
 */
proto.finxai_web.finxai_trade.prototype.setAvgFillPx = function(value) {
  return jspb.Message.setProto3FloatField(this, 8, value);
};


/**
 * optional double order_qty = 9;
 * @return {number}
 */
proto.finxai_web.finxai_trade.prototype.getOrderQty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 9, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.finxai_web.finxai_trade} returns this
 */
proto.finxai_web.finxai_trade.prototype.setOrderQty = function(value) {
  return jspb.Message.setProto3FloatField(this, 9, value);
};


/**
 * optional double filled_qty = 10;
 * @return {number}
 */
proto.finxai_web.finxai_trade.prototype.getFilledQty = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 10, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.finxai_web.finxai_trade} returns this
 */
proto.finxai_web.finxai_trade.prototype.setFilledQty = function(value) {
  return jspb.Message.setProto3FloatField(this, 10, value);
};


/**
 * optional string account = 11;
 * @return {string}
 */
proto.finxai_web.finxai_trade.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_trade} returns this
 */
proto.finxai_web.finxai_trade.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional OrderStatus order_status = 12;
 * @return {!proto.finxai_web.OrderStatus}
 */
proto.finxai_web.finxai_trade.prototype.getOrderStatus = function() {
  return /** @type {!proto.finxai_web.OrderStatus} */ (jspb.Message.getFieldWithDefault(this, 12, 0));
};


/**
 * @param {!proto.finxai_web.OrderStatus} value
 * @return {!proto.finxai_web.finxai_trade} returns this
 */
proto.finxai_web.finxai_trade.prototype.setOrderStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 12, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.finxai_web.finxai_mkt_data.prototype.toObject = function(opt_includeInstance) {
  return proto.finxai_web.finxai_mkt_data.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.finxai_web.finxai_mkt_data} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.finxai_web.finxai_mkt_data.toObject = function(includeInstance, msg) {
  var f, obj = {
    securityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bidPx: jspb.Message.getFloatingPointFieldWithDefault(msg, 2, 0.0),
    bidSz: jspb.Message.getFloatingPointFieldWithDefault(msg, 3, 0.0),
    offerPx: jspb.Message.getFloatingPointFieldWithDefault(msg, 4, 0.0),
    offerSz: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    lastTradedPx: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0),
    lastTradedSz: jspb.Message.getFloatingPointFieldWithDefault(msg, 7, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.finxai_web.finxai_mkt_data}
 */
proto.finxai_web.finxai_mkt_data.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.finxai_web.finxai_mkt_data;
  return proto.finxai_web.finxai_mkt_data.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.finxai_web.finxai_mkt_data} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.finxai_web.finxai_mkt_data}
 */
proto.finxai_web.finxai_mkt_data.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurityId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBidPx(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setBidSz(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOfferPx(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setOfferSz(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastTradedPx(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setLastTradedSz(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.finxai_web.finxai_mkt_data.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.finxai_web.finxai_mkt_data.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.finxai_web.finxai_mkt_data} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.finxai_web.finxai_mkt_data.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSecurityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBidPx();
  if (f !== 0.0) {
    writer.writeDouble(
      2,
      f
    );
  }
  f = message.getBidSz();
  if (f !== 0.0) {
    writer.writeDouble(
      3,
      f
    );
  }
  f = message.getOfferPx();
  if (f !== 0.0) {
    writer.writeDouble(
      4,
      f
    );
  }
  f = message.getOfferSz();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getLastTradedPx();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
  f = message.getLastTradedSz();
  if (f !== 0.0) {
    writer.writeDouble(
      7,
      f
    );
  }
};


/**
 * optional string security_id = 1;
 * @return {string}
 */
proto.finxai_web.finxai_mkt_data.prototype.getSecurityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_mkt_data} returns this
 */
proto.finxai_web.finxai_mkt_data.prototype.setSecurityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional double bid_px = 2;
 * @return {number}
 */
proto.finxai_web.finxai_mkt_data.prototype.getBidPx = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 2, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.finxai_web.finxai_mkt_data} returns this
 */
proto.finxai_web.finxai_mkt_data.prototype.setBidPx = function(value) {
  return jspb.Message.setProto3FloatField(this, 2, value);
};


/**
 * optional double bid_sz = 3;
 * @return {number}
 */
proto.finxai_web.finxai_mkt_data.prototype.getBidSz = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 3, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.finxai_web.finxai_mkt_data} returns this
 */
proto.finxai_web.finxai_mkt_data.prototype.setBidSz = function(value) {
  return jspb.Message.setProto3FloatField(this, 3, value);
};


/**
 * optional double offer_px = 4;
 * @return {number}
 */
proto.finxai_web.finxai_mkt_data.prototype.getOfferPx = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 4, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.finxai_web.finxai_mkt_data} returns this
 */
proto.finxai_web.finxai_mkt_data.prototype.setOfferPx = function(value) {
  return jspb.Message.setProto3FloatField(this, 4, value);
};


/**
 * optional double offer_sz = 5;
 * @return {number}
 */
proto.finxai_web.finxai_mkt_data.prototype.getOfferSz = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.finxai_web.finxai_mkt_data} returns this
 */
proto.finxai_web.finxai_mkt_data.prototype.setOfferSz = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double last_traded_px = 6;
 * @return {number}
 */
proto.finxai_web.finxai_mkt_data.prototype.getLastTradedPx = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.finxai_web.finxai_mkt_data} returns this
 */
proto.finxai_web.finxai_mkt_data.prototype.setLastTradedPx = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};


/**
 * optional double last_traded_sz = 7;
 * @return {number}
 */
proto.finxai_web.finxai_mkt_data.prototype.getLastTradedSz = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 7, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.finxai_web.finxai_mkt_data} returns this
 */
proto.finxai_web.finxai_mkt_data.prototype.setLastTradedSz = function(value) {
  return jspb.Message.setProto3FloatField(this, 7, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.finxai_web.finxai_instrument_leg.prototype.toObject = function(opt_includeInstance) {
  return proto.finxai_web.finxai_instrument_leg.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.finxai_web.finxai_instrument_leg} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.finxai_web.finxai_instrument_leg.toObject = function(includeInstance, msg) {
  var f, obj = {
    legId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    securityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tickerSymbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bbgTicker: jspb.Message.getFieldWithDefault(msg, 4, ""),
    priceRatio: jspb.Message.getFloatingPointFieldWithDefault(msg, 5, 0.0),
    sizeRatio: jspb.Message.getFloatingPointFieldWithDefault(msg, 6, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.finxai_web.finxai_instrument_leg}
 */
proto.finxai_web.finxai_instrument_leg.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.finxai_web.finxai_instrument_leg;
  return proto.finxai_web.finxai_instrument_leg.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.finxai_web.finxai_instrument_leg} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.finxai_web.finxai_instrument_leg}
 */
proto.finxai_web.finxai_instrument_leg.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLegId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTickerSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBbgTicker(value);
      break;
    case 5:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setPriceRatio(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readDouble());
      msg.setSizeRatio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.finxai_web.finxai_instrument_leg.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.finxai_web.finxai_instrument_leg.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.finxai_web.finxai_instrument_leg} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.finxai_web.finxai_instrument_leg.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLegId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSecurityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTickerSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBbgTicker();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPriceRatio();
  if (f !== 0.0) {
    writer.writeDouble(
      5,
      f
    );
  }
  f = message.getSizeRatio();
  if (f !== 0.0) {
    writer.writeDouble(
      6,
      f
    );
  }
};


/**
 * optional int32 leg_id = 1;
 * @return {number}
 */
proto.finxai_web.finxai_instrument_leg.prototype.getLegId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.finxai_web.finxai_instrument_leg} returns this
 */
proto.finxai_web.finxai_instrument_leg.prototype.setLegId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string security_id = 2;
 * @return {string}
 */
proto.finxai_web.finxai_instrument_leg.prototype.getSecurityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_instrument_leg} returns this
 */
proto.finxai_web.finxai_instrument_leg.prototype.setSecurityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ticker_symbol = 3;
 * @return {string}
 */
proto.finxai_web.finxai_instrument_leg.prototype.getTickerSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_instrument_leg} returns this
 */
proto.finxai_web.finxai_instrument_leg.prototype.setTickerSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string bbg_ticker = 4;
 * @return {string}
 */
proto.finxai_web.finxai_instrument_leg.prototype.getBbgTicker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_instrument_leg} returns this
 */
proto.finxai_web.finxai_instrument_leg.prototype.setBbgTicker = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional double price_ratio = 5;
 * @return {number}
 */
proto.finxai_web.finxai_instrument_leg.prototype.getPriceRatio = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 5, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.finxai_web.finxai_instrument_leg} returns this
 */
proto.finxai_web.finxai_instrument_leg.prototype.setPriceRatio = function(value) {
  return jspb.Message.setProto3FloatField(this, 5, value);
};


/**
 * optional double size_ratio = 6;
 * @return {number}
 */
proto.finxai_web.finxai_instrument_leg.prototype.getSizeRatio = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 6, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.finxai_web.finxai_instrument_leg} returns this
 */
proto.finxai_web.finxai_instrument_leg.prototype.setSizeRatio = function(value) {
  return jspb.Message.setProto3FloatField(this, 6, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.finxai_web.finxai_instrument.prototype.toObject = function(opt_includeInstance) {
  return proto.finxai_web.finxai_instrument.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.finxai_web.finxai_instrument} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.finxai_web.finxai_instrument.toObject = function(includeInstance, msg) {
  var f, obj = {
    legId: jspb.Message.getFieldWithDefault(msg, 1, 0),
    securityId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    tickerSymbol: jspb.Message.getFieldWithDefault(msg, 3, ""),
    bbgTicker: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    account: jspb.Message.getFieldWithDefault(msg, 6, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.finxai_web.finxai_instrument}
 */
proto.finxai_web.finxai_instrument.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.finxai_web.finxai_instrument;
  return proto.finxai_web.finxai_instrument.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.finxai_web.finxai_instrument} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.finxai_web.finxai_instrument}
 */
proto.finxai_web.finxai_instrument.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLegId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurityId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setTickerSymbol(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setBbgTicker(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.finxai_web.finxai_instrument.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.finxai_web.finxai_instrument.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.finxai_web.finxai_instrument} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.finxai_web.finxai_instrument.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLegId();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getSecurityId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getTickerSymbol();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getBbgTicker();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
};


/**
 * optional int32 leg_id = 1;
 * @return {number}
 */
proto.finxai_web.finxai_instrument.prototype.getLegId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.finxai_web.finxai_instrument} returns this
 */
proto.finxai_web.finxai_instrument.prototype.setLegId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional string security_id = 2;
 * @return {string}
 */
proto.finxai_web.finxai_instrument.prototype.getSecurityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_instrument} returns this
 */
proto.finxai_web.finxai_instrument.prototype.setSecurityId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string ticker_symbol = 3;
 * @return {string}
 */
proto.finxai_web.finxai_instrument.prototype.getTickerSymbol = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_instrument} returns this
 */
proto.finxai_web.finxai_instrument.prototype.setTickerSymbol = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string bbg_ticker = 4;
 * @return {string}
 */
proto.finxai_web.finxai_instrument.prototype.getBbgTicker = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_instrument} returns this
 */
proto.finxai_web.finxai_instrument.prototype.setBbgTicker = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.finxai_web.finxai_instrument.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_instrument} returns this
 */
proto.finxai_web.finxai_instrument.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string account = 6;
 * @return {string}
 */
proto.finxai_web.finxai_instrument.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_instrument} returns this
 */
proto.finxai_web.finxai_instrument.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.finxai_web.finxai_strategy.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.finxai_web.finxai_strategy.prototype.toObject = function(opt_includeInstance) {
  return proto.finxai_web.finxai_strategy.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.finxai_web.finxai_strategy} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.finxai_web.finxai_strategy.toObject = function(includeInstance, msg) {
  var f, obj = {
    securityId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    instrument: (f = msg.getInstrument()) && proto.finxai_web.finxai_instrument.toObject(includeInstance, f),
    instLegsList: jspb.Message.toObjectList(msg.getInstLegsList(),
    proto.finxai_web.finxai_instrument_leg.toObject, includeInstance),
    account: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.finxai_web.finxai_strategy}
 */
proto.finxai_web.finxai_strategy.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.finxai_web.finxai_strategy;
  return proto.finxai_web.finxai_strategy.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.finxai_web.finxai_strategy} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.finxai_web.finxai_strategy}
 */
proto.finxai_web.finxai_strategy.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSecurityId(value);
      break;
    case 2:
      var value = new proto.finxai_web.finxai_instrument;
      reader.readMessage(value,proto.finxai_web.finxai_instrument.deserializeBinaryFromReader);
      msg.setInstrument(value);
      break;
    case 3:
      var value = new proto.finxai_web.finxai_instrument_leg;
      reader.readMessage(value,proto.finxai_web.finxai_instrument_leg.deserializeBinaryFromReader);
      msg.addInstLegs(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccount(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.finxai_web.finxai_strategy.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.finxai_web.finxai_strategy.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.finxai_web.finxai_strategy} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.finxai_web.finxai_strategy.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSecurityId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getInstrument();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.finxai_web.finxai_instrument.serializeBinaryToWriter
    );
  }
  f = message.getInstLegsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.finxai_web.finxai_instrument_leg.serializeBinaryToWriter
    );
  }
  f = message.getAccount();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string security_id = 1;
 * @return {string}
 */
proto.finxai_web.finxai_strategy.prototype.getSecurityId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_strategy} returns this
 */
proto.finxai_web.finxai_strategy.prototype.setSecurityId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional finxai_instrument instrument = 2;
 * @return {?proto.finxai_web.finxai_instrument}
 */
proto.finxai_web.finxai_strategy.prototype.getInstrument = function() {
  return /** @type{?proto.finxai_web.finxai_instrument} */ (
    jspb.Message.getWrapperField(this, proto.finxai_web.finxai_instrument, 2));
};


/**
 * @param {?proto.finxai_web.finxai_instrument|undefined} value
 * @return {!proto.finxai_web.finxai_strategy} returns this
*/
proto.finxai_web.finxai_strategy.prototype.setInstrument = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.finxai_web.finxai_strategy} returns this
 */
proto.finxai_web.finxai_strategy.prototype.clearInstrument = function() {
  return this.setInstrument(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.finxai_web.finxai_strategy.prototype.hasInstrument = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated finxai_instrument_leg inst_legs = 3;
 * @return {!Array<!proto.finxai_web.finxai_instrument_leg>}
 */
proto.finxai_web.finxai_strategy.prototype.getInstLegsList = function() {
  return /** @type{!Array<!proto.finxai_web.finxai_instrument_leg>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.finxai_web.finxai_instrument_leg, 3));
};


/**
 * @param {!Array<!proto.finxai_web.finxai_instrument_leg>} value
 * @return {!proto.finxai_web.finxai_strategy} returns this
*/
proto.finxai_web.finxai_strategy.prototype.setInstLegsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.finxai_web.finxai_instrument_leg=} opt_value
 * @param {number=} opt_index
 * @return {!proto.finxai_web.finxai_instrument_leg}
 */
proto.finxai_web.finxai_strategy.prototype.addInstLegs = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.finxai_web.finxai_instrument_leg, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.finxai_web.finxai_strategy} returns this
 */
proto.finxai_web.finxai_strategy.prototype.clearInstLegsList = function() {
  return this.setInstLegsList([]);
};


/**
 * optional string account = 4;
 * @return {string}
 */
proto.finxai_web.finxai_strategy.prototype.getAccount = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.finxai_web.finxai_strategy} returns this
 */
proto.finxai_web.finxai_strategy.prototype.setAccount = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.finxai_web.Side = {
  BUY: 0,
  SELL: 1
};

/**
 * @enum {number}
 */
proto.finxai_web.OrderStatus = {
  ORD_STATUS_UNSPECIFIED: 0,
  ORD_STATUS_CANCELED: 1,
  ORD_STATUS_DONE_FOR_DAY: 2,
  ORD_STATUS_FILLED: 3,
  ORD_STATUS_NEW: 4,
  ORD_STATUS_PARTIALLY_FILLED: 5,
  ORD_STATUS_PENDING_CANCEL: 6,
  ORD_STATUS_REJECTED: 7,
  ORD_STATUS_STOPPED: 8,
  ORD_STATUS_PENDING_NEW: 9,
  ORD_STATUS_SUSPENDED: 10,
  ORD_STATUS_CALCULATED: 11,
  ORD_STATUS_EXPIRED: 12,
  ORD_STATUS_ACCEPTED_FOR_BIDDING: 13,
  ORD_STATUS_PENDING_REPLACE: 14,
  ORD_STATUS_REPLACED: 15
};

goog.object.extend(exports, proto.finxai_web);
