

const conditionalRowStyle = [{
    when: (row) => row.side == true,
    style: {
        backgroundColor: '#097969',
        color: 'white',
        fontWeight: 'bold',
    }
},
{
    when: row => row.side === false,
    style: {
        backgroundColor: '#d8392b',
        color: 'white',
        fontWeight: 'bold',

    }
},


]

// const conditionalStatusStyle = [
//     {
//         when: (row) => row.side === 'BUY',
//         style: {
//             color: 'white',
//             fontWeight: 'bold',
//             background: 'green', // Set background color for BUY
//         },
//     },
//     {
//         when: (row) => row.side === 'SELL',
//         style: {
//             color: 'white',
//             fontWeight: 'bold',
//             background: 'red', // Set background color for SELL
//         },
//     },

//     {
//         when: (row) => row.security_id,
//         style: {

//             fontWeight: 'bold',
//         },
//     },




// ];

export const dummyTradeColumnNames = [

    // {
    //     name: 'Order ID',
    //     selector: row => row.order_id
    // },
    // {
    //     name: 'Security ID',
    //     selector: row => row.security_id,

    // },
    {
        name: 'Instrument',
        selector: row => row.ticker_symbol,
    },
    {
        name: "Side",
        selector: (row) => row.side,
    },

    {
        name: "Order Status",
        selector: (row) => row.orderStatus,
    },


    {
        name: 'Bid Price',
        selector: (row) => row.bid_px,
    },

    {
        name: 'Offer Price',
        selector: (row) => row.offer_px,
    },
    {
        name: 'Order Price',
        selector: row => row.price,
    },
    // {
    //     name: 'Average Fill Price',
    //     selector: row => row.avg_fill_px,
    // },
    {
        name: 'Filled/Size',
        selector: row => row.order_qty,
    },

    // {
    //     name: 'Filled Quantity',
    //     selector: row => row.filled_qty,
    // },
    {
        name: 'Account',
        selector: row => row.account,
    }
];




export const dummyMarketColumnNames = [
    {
        name: 'Instrument',
        selector: (row) => row.security_id,
    },
    {
        name: 'Bid Price',
        selector: (row) => row.bid_px,
    },
    // {
    //     name: 'Bid Size',
    //     selector: (row) => row.bid_sz,
    // },
    {
        name: 'Offer Price',
        selector: (row) => row.offer_px,
    },
    // {
    //     name: 'Offer Size',
    //     selector: (row) => row.offer_sz,
    // },
    // {
    //     name: 'Last Traded Price',
    //     selector: (row) => row.last_traded_px,
    // },
    // {
    //     name: 'Last Traded Size',
    //     selector: (row) => row.last_traded_sz,
    // },
];

/// Instrument data
export const dummyInstrumentData = [
    {
        leg_id: 1,
        security_id: 'SEC001',
        ticker_symbol: 'AAPL',
        bbg_ticker: 'AAPL US',
    },
    {
        leg_id: 2,
        security_id: 'SEC002',
        ticker_symbol: 'MSFT',
        bbg_ticker: 'MSFT US',
    },
    {
        leg_id: 3,
        security_id: 'SEC003',
        ticker_symbol: 'GOOGL',
        bbg_ticker: 'GOOGL US',
    },
    {
        leg_id: 4,
        security_id: 'SEC004',
        ticker_symbol: 'AMZN',
        bbg_ticker: 'AMZN US',
    },
    {
        leg_id: 5,
        security_id: 'SEC005',
        ticker_symbol: 'TSLA',
        bbg_ticker: 'TSLA US',
    },
    {
        leg_id: 6,
        security_id: 'SEC006',
        ticker_symbol: 'NFLX',
        bbg_ticker: 'NFLX US',
    },
    {
        leg_id: 7,
        security_id: 'SEC007',
        ticker_symbol: 'FB',
        bbg_ticker: 'FB US',
    },
    {
        leg_id: 8,
        security_id: 'SEC008',
        ticker_symbol: 'NVDA',
        bbg_ticker: 'NVDA US',
    },
];

export const dummyInstrumentColumnNames = [
    {
        name: 'Leg ID',
        selector: (row) => row.leg_id,
    },
    {
        name: 'Security ID',
        selector: (row) => row.security_id,
    },
    {
        name: 'Ticker Symbol',
        selector: (row) => row.ticker_symbol,
    },
    {
        name: 'BBG Ticker',
        selector: (row) => row.bbg_ticker,
    },
];


/// Instrument Leg Data

export const dummyInstrumentlegData = [
    {
        leg_id: 1,
        security_id: 'SEC001',
        ticker_symbol: 'AAPL',
        bbg_ticker: 'AAPL US',
        price_ratio: 1.2,
        size_ratio: 0.8,
    },
    {
        leg_id: 2,
        security_id: 'SEC002',
        ticker_symbol: 'MSFT',
        bbg_ticker: 'MSFT US',
        price_ratio: 1.1,
        size_ratio: 1.0,
    },
    {
        leg_id: 3,
        security_id: 'SEC003',
        ticker_symbol: 'GOOGL',
        bbg_ticker: 'GOOGL US',
        price_ratio: 1.3,
        size_ratio: 0.7,
    },
    {
        leg_id: 4,
        security_id: 'SEC004',
        ticker_symbol: 'AMZN',
        bbg_ticker: 'AMZN US',
        price_ratio: 1.5,
        size_ratio: 0.9,
    },
    {
        leg_id: 5,
        security_id: 'SEC005',
        ticker_symbol: 'TSLA',
        bbg_ticker: 'TSLA US',
        price_ratio: 1.0,
        size_ratio: 1.1,
    },
    {
        leg_id: 6,
        security_id: 'SEC006',
        ticker_symbol: 'NFLX',
        bbg_ticker: 'NFLX US',
        price_ratio: 1.4,
        size_ratio: 0.6,
    },
    {
        leg_id: 7,
        security_id: 'SEC007',
        ticker_symbol: 'FB',
        bbg_ticker: 'FB US',
        price_ratio: 1.2,
        size_ratio: 0.7,
    },
    {
        leg_id: 8,
        security_id: 'SEC008',
        ticker_symbol: 'NVDA',
        bbg_ticker: 'NVDA US',
        price_ratio: 1.3,
        size_ratio: 0.8,
    },
];


export const dummyInstrumentLegColumnNames = [
    {
        name: 'Leg ID',
        selector: (row) => row.leg_id,
    },
    {
        name: 'Security ID',
        selector: (row) => row.security_id,
    },
    {
        name: 'Ticker Symbol',
        selector: (row) => row.ticker_symbol,
    },
    {
        name: 'BBG Ticker',
        selector: (row) => row.bbg_ticker,
    },
    {
        name: 'Price Ratio',
        selector: (row) => row.price_ratio,
    },
    {
        name: 'Size Ratio',
        selector: (row) => row.size_ratio,
    },
];


export const dummyDataNavigationOptions = ["Orders",
    "Market Data",
    "Research"
    // "FinXAi Market Data", "FinXAi Instrument Data",

    // "FinXAi Instrument Leg Data"
]






export const kDummyTradeData = [
    {
      "order_id": "ORD12345",
      "parent_ord_id": "PARENTORD123",
      "security_id": "SEC123",
      "ticker_symbol": "AAPL",
      "price": 150.25,
      "bid_px": 150.20,
      "offer_px": 150.30,
      "avg_fill_px": 150.22,
      "order_qty": 100,
      "filled_qty": 50,
      "account": "ACC1001",
      "side": "buy",
      "orderStatus": 1
    },
    {
      "order_id": "ORD12346",
      "parent_ord_id": "PARENTORD124",
      "security_id": "SEC124",
      "ticker_symbol": "GOOG",
      "price": 2800.75,
      "bid_px": 2800.50,
      "offer_px": 2801.00,
      "avg_fill_px": 2800.60,
      "order_qty": 150,
      "filled_qty": 100,
      "account": "ACC1002",
      "side": "sell",
      "orderStatus": 2
    },
    {
      "order_id": "ORD12347",
      "parent_ord_id": "PARENTORD125",
      "security_id": "SEC125",
      "ticker_symbol": "AMZN",
      "price": 3400.50,
      "avg_fill_px": 3400.40,
      "order_qty": 200,
      "filled_qty": 200,
      "account": "ACC1003",
      "side": "buy",
      "orderStatus": 1
    },
    {
      "order_id": "ORD12348",
      "parent_ord_id": "PARENTORD126",
      "security_id": "SEC126",
      "ticker_symbol": "NFLX",
      "price": 620.00,
      "bid_px": 619.90,
      "offer_px": 620.10,
      "avg_fill_px": 620.05,
      "order_qty": 50,
      "filled_qty": 50,
      "account": "ACC1004",
      "side": "sell",
      "orderStatus": 3
    },
    {
      "order_id": "ORD12349",
      "parent_ord_id": "PARENTORD127",
      "security_id": "SEC127",
      "ticker_symbol": "TSLA",
      "price": 750.00,
      "bid_px": 749.90,
      "offer_px": 750.20,
      "avg_fill_px": 750.10,
      "order_qty": 300,
      "filled_qty": 150,
      "account": "ACC1005",
      "side": "buy",
      "orderStatus": 1
    },
    {
      "order_id": "ORD12350",
      "parent_ord_id": "PARENTORD128",
      "security_id": "SEC128",
      "ticker_symbol": "FB",
      "price": 350.50,
      "bid_px": 350.40,
      "offer_px": 350.60,
      "avg_fill_px": 350.55,
      "order_qty": 400,
      "filled_qty": 400,
      "account": "ACC1006",
      "side": "sell",
      "orderStatus": 2
    },
    {
      "order_id": "ORD12351",
      "parent_ord_id": "PARENTORD129",
      "security_id": "SEC129",
      "ticker_symbol": "MSFT",
      "price": 299.99,
      "avg_fill_px": 299.90,
      "order_qty": 120,
      "filled_qty": 100,
      "account": "ACC1007",
      "side": "buy",
      "orderStatus": 1
    },
    {
      "order_id": "ORD12352",
      "parent_ord_id": "PARENTORD130",
      "security_id": "SEC130",
      "ticker_symbol": "BABA",
      "price": 200.00,
      "bid_px": 199.90,
      "offer_px": 200.10,
      "avg_fill_px": 200.05,
      "order_qty": 500,
      "filled_qty": 450,
      "account": "ACC1008",
      "side": "sell",
      "orderStatus": 2
    },
    {
      "order_id": "ORD12353",
      "parent_ord_id": "PARENTORD131",
      "security_id": "SEC131",
      "ticker_symbol": "NVDA",
      "price": 700.00,
      "bid_px": "N/A",
      "offer_px": "N/A",
      "avg_fill_px": 700.00,
      "order_qty": 250,
      "filled_qty": 250,
      "account": "ACC1009",
      "side": "buy",
      "orderStatus": 1
    },
    {
      "order_id": "ORD12354",
      "parent_ord_id": "PARENTORD132",
      "security_id": "SEC132",
      "ticker_symbol": "UBER",
      "price": 50.25,
      "bid_px": 50.20,
      "offer_px": 50.30,
      "avg_fill_px": 50.22,
      "order_qty": 600,
      "filled_qty": 600,
      "account": "ACC1010",
      "side": "sell",
      "orderStatus": 2
    }
  ]
  