import React from "react";

interface CustomButtonProps {
  onClick?: () => void;
  className?: string;
  label: string;
}

export const CustomButton: React.FC<CustomButtonProps> = ({
  onClick,
  className,
  label,
}) => {
  return (
    <div className="my-2 justify-center items-center flex">
      <button
        onClick={onClick}
        className={`p-2 bg-primary rounded-xl shadow-2xl text-black transform transition-transform duration-200 active:scale-90 ${className}`}
      >
        {label}
      </button>
    </div>
  );
};
