import React from "react";
import useWebSocket from "react-use-websocket";
import { Box, Typography, Paper } from "@mui/material";
import { green, orange, red, grey } from "@mui/material/colors";
import logger from "../utils/logger";

const WebSocketConnectionStatusIndicator = () => {
  const { readyState } = useWebSocket(
    process.env.REACT_APP_WEB_SOCKET_ENDPOINT as string,
    {
      onOpen: () => {
        logger.info("WebSocket connected");
      },
      onClose: () => {
        logger.warn("WebSocket disconnected");
      },
      share: true,
      shouldReconnect: () => true,
      
      
    }
  );

  const getStatusText = () => {
    switch (readyState) {
      case WebSocket.CONNECTING:
        return {
          text: "Connecting",
          color: orange[500],
        };
      case WebSocket.OPEN:
        return {
          text: "Connected",
          color: green[500],
        };
      case WebSocket.CLOSING:
      case WebSocket.CLOSED:
        return {
          text: "Offline",
          color: red[500],
        };
      default:
        return {
          text: "Unknown",
          color: grey[500],
        };
    }
  };

  const status = getStatusText();

  return (
    <Paper
      className="w-32"
      elevation={3}
      sx={{
        bottom: 0,
        left: 0,
        right: 0,
        padding: 0.5,
        borderRadius: "10px",
        fontWeight: "bold",
        backgroundColor: "#5D8AD8",
        textAlign: "center",
        cursor: readyState === WebSocket.CLOSED ? "pointer" : "default",
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="center">
        <Box
          sx={{
            width: 16,
            height: 16,
            borderRadius: "50%",
            backgroundColor: status.color,
            marginRight: 1,
            boxShadow: `0 0 10px 3px ${status.color}`,
          }}
        />
        <Typography variant="body2" color="white">
          {status.text}
        </Typography>
      </Box>
    </Paper>
  );
};

export default WebSocketConnectionStatusIndicator;
