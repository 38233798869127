import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { GridDataView } from "./views/grid-data-view";
import { LoginView } from "./views/login-view";

import {
  message_envelop,
  payload_type,
} from "./models/protobuff-objects/finxai_msg_wrapper_pb";
import {
  user_login_request,
  user_login_response,
  user_subscription_request,
} from "./models/protobuff-objects/finxai_user_pb";
import { finxai_trade } from "./models/protobuff-objects/finxai_web_pb";
import { message_parser } from "./utils/message_parser";
import WebSocketTester from "./views/websocket-test";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoginView />} />
        <Route path="/home" element={<GridDataView />} />
        <Route path="/grid" element={<GridDataView />} />
        <Route path="/testws" element={<WebSocketTester />} />
      </Routes>
    </Router>
  );
}

export default App;
