import store, { AppThunk } from "../store";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { user_login_response } from "../../models/protobuff-objects/finxai_user_pb";
import { DataGridWebSocketService } from "../../api/services/data-grid-websocket-service";
import { SendMessage } from "react-use-websocket";
import { message_envelop } from "../../models/protobuff-objects/finxai_msg_wrapper_pb";
import { FinxAiUser } from "../../models/finxaiUser";
import logger from "../../utils/logger";


interface UserState {
  isAuthenticated: boolean;
  loggedInUser: FinxAiUser | null;
  isLoggingIn: boolean;
  loginError: string | null; 
}

const initialState: UserState = {
  isAuthenticated: false,
  isLoggingIn: false,
  loginError: null,
  loggedInUser: null
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setLoginSuccess(state, action :PayloadAction<FinxAiUser>) {
      state.isAuthenticated = true;
      state.isLoggingIn = false;
      state.loggedInUser = action.payload;
    },

   

    setIsLoggingIn(state, action: PayloadAction<boolean>) {
      state.isLoggingIn = action.payload;
    },
    setLoginError(state, action: PayloadAction<string>) {
      state.loginError = action.payload; 
      state.isLoggingIn = false;
    },
    logout(state) {
      state.isAuthenticated = false;
      state.loggedInUser = null;
   
    },
  },
});

export const { setLoginSuccess, logout, setIsLoggingIn,  setLoginError } = userSlice.actions;
export default userSlice.reducer;


// Function to get or create the WebSocket instance
export const getWebSocketInstance = (): DataGridWebSocketService => {
  let webSocketInstance = null;
  if (!webSocketInstance) {
    webSocketInstance = new DataGridWebSocketService();
  }
  return webSocketInstance;
};

// Thunk to handle login
export const loginUser = (
  credentials: UserCreds
): AppThunk<Promise<message_envelop>> => async (dispatch) => {
  try {
    dispatch(setIsLoggingIn(true)); 
    const socketService = getWebSocketInstance();

    const loginRequestMessage = socketService.buildLoginRequest(
      credentials.username,
      credentials.password,
      credentials.isQa === "true"
    );

    dispatch(setIsLoggingIn(false)); 
    return loginRequestMessage;
  } catch (error) {
    console.error("Login error:", error);
    dispatch(setIsLoggingIn(false));
    throw error; 
  }
};

export const handleLoginResponse  = (response :  user_login_response) : AppThunk => async (dispatch) =>  {
  if(response.getIsLoggedin())
  {
    logger.debug("User is logged in")
    dispatch(setIsLoggingIn(true)); 
  }
}

interface UserCreds {
  username: string;
  password: string;
  isQa: string;
}
