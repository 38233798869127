import React from "react";

interface QuantityCellProps {
  quantity: number;
  filledQuantity: number;
}

const QuantityCell: React.FC<QuantityCellProps> = ({
  quantity,
  filledQuantity,
}) => {
  // Ensure filledQuantity does not exceed quantity
  const safeFilledQuantity =
    filledQuantity > quantity ? quantity : filledQuantity;
  const fillPercentage = (safeFilledQuantity / quantity) * 100;

  return (
    <div className="quantity-cell relative bg-black text-white px-2 py-1 rounded-full w-28">
      {/* Progress Bar */}
      <div
        className="absolute inset-y-0 left-0 bg-green-600 rounded-full transition-all duration-300"
        style={{ width: `${fillPercentage}%` }}
      ></div>
      {/* Text */}
      <div className="relative text-center text-sm">
        {safeFilledQuantity}/{quantity}
      </div>
    </div>
  );
};

export default QuantityCell;
