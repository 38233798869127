import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import dataReducer from './slices/dataSlice';
import userReducer from './slices/userSlice';
import uiReducer from './slices/uiSlice';
import dataTableReducer from './slices/dataTableSlice';

const store = configureStore({
  reducer: {
    data: dataReducer,
    user: userReducer,
    ui: uiReducer,
    dataTable: dataTableReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export default store;