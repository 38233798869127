import logger from "./logger";

export const setCache = (key: string, data: any, ttl?: number) => {
    const expires = Date.now() + (ttl ?? 2) * 86400000; // Convert days to millisecond;
    const item = { data, expires };
    localStorage.setItem(key, JSON.stringify(item));
    logger.debug(`Saved item ${key} with expiry set to ${expires} and with  following data: `)
    logger.debug( JSON.stringify(item))
  };

  export const getCache = (key: string) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    logger.debug(`Cache: ${key} Item exists`)
    if (Date.now() > item.expires) {
      localStorage.removeItem(key);
      return null;
    }

    if (Array.isArray(item.data)) {
      return item.data.map((column: any) => ({
          ...column,
        
      }));
  }

    return item.data;
  };

  export const clearAllCache = () => {
    localStorage.clear();
    logger.debug("All cache cleared.");
};