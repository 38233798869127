import React, { ReactNode } from "react";

interface CustomPopUpModalProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
}

const CustomPopUpModal: React.FC<CustomPopUpModalProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  if (!isOpen) return null;

  return (
    <div
      onClick={onClose}
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-tableHeaderBg p-4 border border-black rounded-2xl shadow-2xl z-60"
      >
        {children}
      </div>
    </div>
  );
};

export default CustomPopUpModal;
