import React, { useEffect, useState } from "react";
import CustomDataTable from "../components/custom-data-table";
import {
  dummyDataNavigationOptions,
  dummyMarketColumnNames,
  dummyTradeColumnNames,
} from "../utils/dummyData";
import { LogoutRounded } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { FinxaiTrade } from "../models/finxaiTrade";
import useWebSocket from "react-use-websocket";
import { DataGridWebSocketService } from "../api/services/data-grid-websocket-service";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import {
  addOrUpdateMarketData,
  addOrUpdateStrategy,
  addOrUpdateTradeOrder,
  postLogoutDataCleanup,
  reloadTradeOrders,
  setTableKey,
} from "../store/slices/dataSlice";
import {
  setCurrentTableIndex,
  setDataFromWsLoading,
} from "../store/slices/uiSlice";
import {
  finxai_mkt_data,
  finxai_strategy,
  finxai_trade,
} from "../models/protobuff-objects/finxai_web_pb";
import {
  getWebSocketInstance,
  logout,
  setLoginSuccess,
} from "../store/slices/userSlice";
import { FinxaiMktData } from "../models/finxaiMktData";
import {
  addGroup,
  addNewDataId,
  clearChangedCells,
  clearNewDataIds,
  setGroups,
  setModalOpen,
} from "../store/slices/dataTableSlice";
import { clearAllCache, getCache, setCache } from "../utils/cache";
import { FinxAiUser } from "../models/finxaiUser";
import { FinxaiStrategy } from "../models/finxaiStrategy";
import { FinxaiInstrument } from "../models/finxaiInstrument";
import CustomPopUpModal from "../components/custom-popup-modal";
import GroupCreationForm from "../components/group-creation-form";
import { FinxaiDataGroup } from "../models/data-group";
import CustomSpeedDial from "../components/custom-speed-dial";
import {
  DynamicLogoWithText,
  FinxAiLogoWithText,
} from "../components/finxai-logo-with-text";
import logger from "../utils/logger";
import { ResearchView } from "./research-view";
import { FinxAiFooter } from "../components/finxai-footer";
import {
  CustomChartComponent,
  dummyChartData,
} from "../components/custom-chart";

export const GridDataView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { tradeOrders, marketData, tableKey, strategies } = useSelector(
    (state: RootState) => state.data
  );
  const navigate = useNavigate();
  const { isDataFromWsLoading, currentTableIndex } = useSelector(
    (state: RootState) => state.ui
  );

  const { modalOpen, groups } = useSelector(
    (state: RootState) => state.dataTable
  );
  const tableData = [
    { col: dummyTradeColumnNames, data: tradeOrders, uid: "order_id" },
    { col: dummyMarketColumnNames, data: marketData, uid: "security_id" },
  ];
  const { loggedInUser } = useSelector((state: RootState) => state.user);
  const [isUserLoaded, setIsUserLoaded] = useState<boolean>(false);

  const handleUserCache = () => {
    const savedUser = getCache("loggedInUser");
    if (savedUser) {
      logger.debug("User exists in cache");
      dispatch(setLoginSuccess(savedUser as FinxAiUser));
      setIsUserLoaded(true);
    } else {
      logger.debug("User not found in cache, redirecting to login");
      navigate("/");
    }
  };

  useEffect(() => {
    handleUserCache();
  }, []);
  const ws = getWebSocketInstance();

  /// Send subscribe request to Websocket server
  useEffect(() => {
    if (isUserLoaded) {
      handleDataSubscription();
      getSavedGroupLayout();
      setTimeout(() => {
        dispatch(setDataFromWsLoading(false));
        logger.debug("Data subscription initialized");
        reloadStateTradeOrders();
      }, 150);
    }
  }, [isUserLoaded, loggedInUser]);

  const reloadStateTradeOrders = () => {
    dispatch(reloadTradeOrders());
    logger.debug("Trade orders bid and offer price data mapped");
  };

  const handleTradeDataReceived = (order: FinxaiTrade) => {
    const isReplacement = tradeOrders.find(
      (existingOrder) => existingOrder.order_id === order.order_id
    );
    dispatch(addOrUpdateTradeOrder(order));
    /// Only add New Data ID if the incoming data is new and
    /// not replaceing existing one
    if (!isReplacement) {
      dispatch(addNewDataId(order.order_id));
    }

    /// To refresh table state whenever new data arrives

    /// To clear the new data animation after 3 seconds
    setTimeout(() => {
      dispatch(clearNewDataIds());
    }, 3000);
    logger.debug("Handled trade order");
  };

  const handleMarketDataReceived = (data: FinxaiMktData) => {
    dispatch(addOrUpdateMarketData(data));
  };

  const handleStrategiesDataRecevied = (data: FinxaiStrategy) => {
    dispatch(addOrUpdateStrategy(data));
  };

  function handleDataSubscription() {
    if (loggedInUser && isUserLoaded) {
      const subReq = ws.buildSubscriptionRequest(loggedInUser);
      /// Send subscription request
      sendMessage(subReq.serializeBinary());
      logger.debug("SUB REQUEST SENT SUCCESSFULLY!");
    }
  }

  const endpoint = process.env.REACT_APP_WEB_SOCKET_ENDPOINT as string;
  const { sendMessage } = useWebSocket(endpoint, {
    onOpen: (event) => {
      logger.debug("Connected to the Grid WebSocket From GRIDVIEW");
    },
    onClose: () => logger.debug("WebSocket connection closed"),
    onError: (event) => console.error("WebSocket error:", event),
    onMessage: async (message) => {
      console.log("Recived message from ws: ");
      const payload = await ws.handleBinaryMessage(message.data);
      if (payload instanceof finxai_trade) {
        logger.debug("Received Trade data from WebSocket");
        const order = ws.buildObject(
          payload,
          marketData,
          strategies
        ) as FinxaiTrade;
        handleTradeDataReceived(order);
      }

      if (payload instanceof finxai_mkt_data) {
        // logger.debug("Received MARKET data from WebSocket");
        const mktData = ws.buildObject(
          payload,
          marketData,
          strategies
        ) as FinxaiMktData;
        handleMarketDataReceived(mktData);
      }

      if (payload instanceof finxai_strategy) {
        logger.debug("RECEIVED FINXAISTATEGY Payload");
        const strategy = ws.buildObject(
          payload,
          marketData,
          strategies
        ) as FinxaiStrategy;
        logger.debug(
          `Strategy with security id ${strategy.instrument.security_id} has ticker symbol has ${strategy.instrument.ticker_symbol}`
        );
        handleStrategiesDataRecevied(strategy);
      }
    },
    shouldReconnect: (closeEvent) => false,
  });

  const handleLogout = () => {
    const logoutPayload = ws.buildLogoutRequest(loggedInUser!);
    /// Send logout request to ws server first
    sendMessage(logoutPayload.serializeBinary());
    /// Clear the locally in-memory cached user
    dispatch(logout());
    /// Clear in memory data
    dispatch(postLogoutDataCleanup());
    /// Clear all user related browser cache
    clearAllCache();
    navigate("/");
  };

  function saveCurrentGroupLayout() {
    if (loggedInUser != null && loggedInUser.username != null) {
      setCache(`${loggedInUser.username}grouplayout`, groups);
      logger.debug("Saved layout!");
    }
  }

  function getSavedGroupLayout() {
    if (loggedInUser != null && loggedInUser.username != null) {
      const cached = getCache(`${loggedInUser.username}grouplayout`);
      if (cached) {
        logger.debug("Cached data exists:", cached);
        dispatch(setGroups(cached));
      } else {
        logger.debug("No data present");
      }
    }
  }

  function handleGroupCreation(group: FinxaiDataGroup) {
    dispatch(addGroup(group));
    dispatch(setModalOpen(false));
    saveCurrentGroupLayout();
  }

  const handleClose = () => dispatch(setModalOpen(false));
  const handleOpen = () => dispatch(setModalOpen(true));
  return (
    <div className="h-screen fledx flex-col">
      {/* Header section with username, logout button, and logo */}
      <div className="flex justify-between items-center w-full">
        {/* Left-aligned username and logout button */}
        <div className="flex items-center ml-auto">
          <div className="bg-tableHeaderBg w-min rounded-bl-xl p-3 items-end justify-end flex text-white">
            <h1 className="mr-2">{loggedInUser?.username}</h1>
            <button onClick={() => handleLogout()} className="text-red-700">
              <LogoutRounded />
            </button>
          </div>
        </div>

        {/* Centered logo */}
        <div className="absolute left-1/2 transform -translate-x-1/2 pt-2">
          <DynamicLogoWithText />
        </div>
      </div>

      <CustomPopUpModal isOpen={modalOpen} onClose={handleClose}>
        <GroupCreationForm
          onSubmit={(group) => {
            handleGroupCreation(group);
            logger.debug(
              `Group name : ${group.groupName}, Selected Ticker: ${group.tickers}`
            );
          }}
          optionNames={Array.from(
            new Set(
              tradeOrders
                .filter((e) => e.ticker_symbol !== "N.A")
                .map((e) => e.order_id)
            )
          )}
        />
      </CustomPopUpModal>

      <div className="justify-center items-center flex p-5 bg-primarybg flex-col">
        <div className="p-2 rounded-lg flex-row flex">
          {dummyDataNavigationOptions.map((option, index) => (
            <button
              key={index}
              onClick={() => {
                dispatch(setTableKey(tableKey + 1));
                dispatch(clearChangedCells());
                return dispatch(setCurrentTableIndex(index));
              }}
              style={{
                transition: "color 0.5s ease-in-out, border 0.5s ease-in-out",
              }}
              className={`${
                currentTableIndex === index
                  ? "text-primary"
                  : "text-cellTextFontColor"
              } m-2 rounded-2xl ${
                index === currentTableIndex
                  ? "border-solid border-2 border-primary"
                  : "border-2 border-transparent"
              }`}
            >
              <nav className="m-2 font-bold">{option}</nav>
            </button>
          ))}
        </div>

        {isDataFromWsLoading ? (
          <div className="text-white text-2xl">Loading...</div>
        ) : currentTableIndex === 2 ? (
          <div style={{ height: "100vh", width: "100vw" }}>
            <ResearchView url={`https://dvtrading.finxai.io//research/index.html`} />
          </div>
        ) : (
          <div className="pb-24">
            <CustomDataTable
              key={tableKey}
              username={loggedInUser?.username}
              enableGrouping={currentTableIndex === 0}
              columns={tableData[currentTableIndex].col}
              data={tableData[currentTableIndex].data}
              uid={tableData[currentTableIndex].uid}
              onRowTap={(e) => {
                if (e) console.log("Row tapped", e);
              }}
            />
          </div>
        )}
      </div>

      {/* {currentTableIndex === 0 && (
        <CustomSpeedDial onOpenClick={handleOpen} groups={groups} />
      )} */}

      <FinxAiFooter></FinxAiFooter>
    </div>
  );
};

export default GridDataView;
