// src/store/slices/dataTableSlice.ts

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FinxaiTrade } from '../../models/finxaiTrade';
import { FinxaiDataGroup } from '../../models/data-group';
import {  Column } from '../../components/custom-data-table';
import { getCache, setCache } from '../../utils/cache';
import logger from '../../utils/logger';

interface DataTableState {
  columnsState: Column[];
  dataState: FinxaiTrade[];
  showAnimation: boolean;
  isHovering: number | null;
  sortDirection: 'asc' | 'desc';
  sortColIndex: number | null;
  modalOpen: boolean;
  groupIndex: number;
  groups: FinxaiDataGroup[];
  searchInput: string;
  searchResults: FinxaiTrade[];
  
  sortColumn: string | null
  newDataIds: string[];
  changedTradeCells: { [key: string]: Set<string> };
  changedMarketDataCells: { [key: string]: Set<string> };
  currentColumnLayout: { [tableIndex: number]: Column[] };
  sortLayoutInitialized: boolean; 
  

}

const initialState: DataTableState = {
  columnsState: [],
  dataState: [],
  showAnimation: false,
  isHovering: null,
  sortDirection: 'asc',
  sortColIndex: null,
  sortColumn: null,
  modalOpen: false,
  groupIndex: 0,
  groups: [{ groupName: "All Data", tickers: ["alldata"] }],
  searchInput: '',
  searchResults: [],
  changedTradeCells: {},
  changedMarketDataCells: {},
  newDataIds: [],
  currentColumnLayout: {},
  sortLayoutInitialized: false,

};

const dataTableSlice = createSlice({
  name: 'dataTable',
  initialState,
  reducers: {
    setColumns(state, action: PayloadAction<Column[]>) {
      state.columnsState = action.payload;
      logger.debug("Updated column state from store: ",action.payload)

    },
    setColumnLayout: (
      state,
      action: PayloadAction<{ tableIndex: number; columns: Column[] }>
    ) => {
      const { tableIndex, columns } = action.payload;
      state.currentColumnLayout[tableIndex] = columns;
      
    },
    setSortColumn(state, action) {
      state.sortColumn = action.payload.column;
      state.sortDirection = action.payload.direction;
    },
    setData(state, action: PayloadAction<FinxaiTrade[]>) {
      state.dataState = action.payload;
    },
    setSortDirection(state, action: PayloadAction<'asc' | 'desc'>) {
      state.sortDirection = action.payload;
    },
    setSortColIndex(state, action: PayloadAction<number | null>) {
      state.sortColIndex = action.payload;
    },
    setGroups(state, action: PayloadAction<FinxaiDataGroup[]>) {
      state.groups = action.payload;
    },
    setGroupIndex(state, action: PayloadAction<number>) {
      state.groupIndex = action.payload;
    },
    setSearchInput(state, action: PayloadAction<string>) {
      state.searchInput = action.payload;
    },
    setSearchResults(state, action: PayloadAction<FinxaiTrade[]>) {
      state.searchResults = action.payload;
    },
    setChangedTradeCells(state, action: PayloadAction<{ [key: string]: Set<string> }>) {
      state.changedTradeCells = action.payload;
      logger.debug("The changed Trade cells are: ", action.payload);
    },

    setChangedMarketDataCells(state, action: PayloadAction<{ [key: string]: Set<string> }>) {   
      state.changedMarketDataCells = action.payload;
      logger.debug("The changed Market Data cells are: ", action.payload);
    },
    setShowAnimation(state, action: PayloadAction<boolean>) {
      state.showAnimation = action.payload;
    },
    setModalOpen(state, action: PayloadAction<boolean>) {
      state.modalOpen = action.payload;
    },
    setIsHovering(state, action: PayloadAction<number | null>) {
      state.isHovering = action.payload;
    },

    setNewDataIds(state, action: PayloadAction<string[]>) {
      state.newDataIds = action.payload;
    },

    clearNewDataIds(state) {
      state.newDataIds = [];
    },
    addGroup(state, action: PayloadAction<FinxaiDataGroup>) {
      state.groups.unshift(action.payload);
      logger.debug("The groups are: ", state.groups)
    },
    updateGroup(state, action: PayloadAction<{ index: number; group: FinxaiDataGroup }>) {
      const { index, group } = action.payload;
      state.groups[index] = group;
    },
    removeGroup(state, action: PayloadAction<number>) {
      state.groups.splice(action.payload, 1);
    },
    addOrUpdateTradeOrder(state, action: PayloadAction<FinxaiTrade>) {
      const index = state.dataState.findIndex(
        (order) => order.order_id === action.payload.order_id
      );
      if (index !== -1) {
        state.dataState[index] = action.payload;
      } else {
        state.dataState.unshift(action.payload);
      }
    },
    removeTradeOrder(state, action: PayloadAction<string>) {
      state.dataState = state.dataState.filter(order => order.order_id !== action.payload);
    },


    initializeSortLayout: (
      state,
      action: PayloadAction<{ 
        username: string;
        tableIndex: number;
      }>
    ) => {
      if (!state.sortLayoutInitialized) {
        const { username, tableIndex } = action.payload;
        const cacheDirKey = `${username}_sortLayoutDirection_${tableIndex}`;
        const cacheColumnNameKey = `${username}_sortLayoutColumnName_${tableIndex}`;

        const cachedSortDir = getCache(cacheDirKey) as 'asc' | 'desc';
        const cachedColumnName = Number(getCache(cacheColumnNameKey));

        if (cachedSortDir && !isNaN(cachedColumnName) && tableIndex === 0) {
          state.sortDirection = cachedSortDir;
          state.sortColIndex = cachedColumnName;
          
          // Re-sort the data using cached values
          if (state.columnsState.length > 0) {
            const column = state.columnsState[cachedColumnName];
            state.dataState.sort((a, b) => {
              const aValue = column.selector(a) ?? '';
              const bValue = column.selector(b) ?? '';

              if (aValue < bValue) return cachedSortDir === 'asc' ? -1 : 1;
              if (aValue > bValue) return cachedSortDir === 'asc' ? 1 : -1;
              return 0;
            });
          }
        }

        state.sortLayoutInitialized = true;
      }
    },
  


    sortData(state, action: PayloadAction<{columnIndex: number,  username?: string,
      skipStatePreserve?: boolean
      tableIndex?: number}>, ) {
      
      const {columnIndex, username, tableIndex, skipStatePreserve} =  action.payload;
 
      const column = state.columnsState[columnIndex];
      const newSortDirection = state.sortDirection === 'asc' ? 'desc' : 'asc';

      state.dataState.sort((a, b) => {
        const aValue = column.selector(a) ?? '';
        const bValue = column.selector(b) ?? '';

        if (aValue < bValue) return newSortDirection === 'asc' ? -1 : 1;
        if (aValue > bValue) return newSortDirection === 'asc' ? 1 : -1;
        return 0;
      });

      state.sortDirection = newSortDirection;
      state.sortColIndex = columnIndex;

       // Cache the sorting layout in local storage
       const cacheDirKey = `${username}_sortLayoutDirection_${tableIndex}`
       const cacheIndexKey = `${username}_sortLayoutColumnName_${tableIndex}`
       setCache(cacheDirKey, newSortDirection);
       setCache(cacheIndexKey, columnIndex)
    },

    swapColumns(state, action: PayloadAction<{ tableIndex:number,  currentIndex: number; newIndex: number, username?: string }>) {
      const { tableIndex, currentIndex, newIndex } = action.payload;
      if (state.columnsState.length >= 2 && state.dataState.length > 0) {
        // Swap columns
        [state.columnsState[currentIndex], state.columnsState[newIndex]] = 
          [state.columnsState[newIndex], state.columnsState[currentIndex]];

          

        // Swap data based on column index
        state.dataState = state.dataState.map(row => {
          const newRow = { ...row };
          const currentColumnName = state.columnsState[currentIndex].name;
          const newColumnName = state.columnsState[newIndex].name;

          [newRow[currentColumnName], newRow[newColumnName]] = 
            [newRow[newColumnName], newRow[currentColumnName]];

          return newRow;
        });

        /// Hold the reorder in dataTableStore for re using
        state.currentColumnLayout[tableIndex] = state.columnsState
        
       // Cache the reordered layout in the browser
    const cacheKey = `${action.payload.username}_tableLayout_${tableIndex}`;
    setCache(cacheKey, state.currentColumnLayout[tableIndex]);

      }
    },
    addTickerToGroup(state, action: PayloadAction<{ groupIndex: number; ticker: string }>) {
      const { groupIndex, ticker } = action.payload;
      if (!state.groups[groupIndex].tickers.includes(ticker)) {
        state.groups[groupIndex].tickers.push(ticker);
      }
    },
    removeTickerFromGroup(state, action: PayloadAction<{ groupIndex: number; ticker: string }>) {
      const { groupIndex, ticker } = action.payload;
      state.groups[groupIndex].tickers = state.groups[groupIndex].tickers.filter(t => t !== ticker);
    },
    clearChangedCells(state) {
      state.changedTradeCells = {};
      state.changedMarketDataCells = {};
    },
    addNewDataId(state, action: PayloadAction<string>) {
      if (!state.newDataIds.includes(action.payload)) {
        state.newDataIds.push(action.payload);
      }
    },
  },

  
});

export const sortDataThunk = createAsyncThunk(
  'data/sort',
  async ({ columnIndex, dataState, columnsState, sortDirection }: { 
    columnIndex: number; 
    dataState: any[]; 
    columnsState: any[]; 
    sortDirection: 'asc' | 'desc'; 
  }) => {
    const column = columnsState[columnIndex];
    const newSortDirection = sortDirection === 'asc' ? 'desc' : 'asc';

    const sortedData = [...dataState].sort((a, b) => {
      const aValue = column.selector(a) ?? '';
      const bValue = column.selector(b) ?? '';

      if (aValue < bValue) return newSortDirection === 'asc' ? -1 : 1;
      if (aValue > bValue) return newSortDirection === 'asc' ? 1 : -1;
      return 0;
    });

    return { sortedData, newSortDirection, columnIndex };
  }
);

export const swapColumnsThunk = createAsyncThunk(
  'data/swapColumns',
  async ({currentIndex, newIndex, dataState, columnsState }: { 

    currentIndex: number; 
    newIndex: number; 
    dataState: any[]; 
    columnsState: any[];
  }) => {
    if (columnsState.length >= 2 && dataState.length > 0) {
      // Swap columns
      [columnsState[currentIndex], columnsState[newIndex]] = 
        [columnsState[newIndex], columnsState[currentIndex]];

      // Swap data based on column index
      const swappedData = dataState.map(row => {
        const newRow = { ...row };
        const currentColumnName = columnsState[currentIndex].name;
        const newColumnName = columnsState[newIndex].name;

        [newRow[currentColumnName], newRow[newColumnName]] = 
          [newRow[newColumnName], newRow[currentColumnName]];

        return newRow;
      });

      return { swappedData, columnsState };
    }

    return { dataState, columnsState }; // Return unchanged data if conditions aren't met
  }
);

export const {
  setColumns,
  setSortColumn,
  setData,
  setSortDirection,
  setSortColIndex,
  setGroups,
  setColumnLayout,
  setGroupIndex,
  setSearchInput,
  setSearchResults,
  setChangedTradeCells,
  setChangedMarketDataCells,
  setShowAnimation,
  setModalOpen,
  setIsHovering,
  addGroup,
  updateGroup,
  removeGroup,
  addOrUpdateTradeOrder,
  removeTradeOrder,
  sortData,
  swapColumns,
  addTickerToGroup,
  removeTickerFromGroup,
  clearChangedCells,
  setNewDataIds,
  clearNewDataIds,
  addNewDataId,
  initializeSortLayout
} = dataTableSlice.actions;

export default dataTableSlice.reducer;
