import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FinxaiTrade } from '../../models/finxaiTrade';
import { FinxaiDataGroup } from '../../models/data-group';
import { FinxaiMktData } from '../../models/finxaiMktData';
import { setNewDataIds } from './dataTableSlice';
import { AppDispatch } from '../store'; 
import { FinxaiStrategy } from '../../models/finxaiStrategy';
import logger from '../../utils/logger';

interface DataState {
    tradeOrders: FinxaiTrade[];
    marketData: FinxaiMktData[];
    groups: FinxaiDataGroup[];
    tableKey: number;
    strategies: FinxaiStrategy[]; 
    sortLayoutInitialized: boolean;

}

const initialState: DataState = {
    tradeOrders: [],
    marketData: [],
    sortLayoutInitialized: false,
    groups: [{groupName: 'All Data', tickers: ["alldata"]}],
    tableKey: 0,
    strategies: []

   
}


  const dataSlice = createSlice({
    name: 'data',
    initialState,
    reducers: {

    initializeDataSort: (state, action: PayloadAction<{ field: keyof FinxaiTrade, order: 'asc' | 'desc' }>)=> {
      if (!state.sortLayoutInitialized) {
        const { field, order } = action.payload;
        state.tradeOrders = state.tradeOrders.slice().sort((a, b) => {
          if (a[field] < b[field]) return order === 'asc' ? -1 : 1;
          if (a[field] > b[field]) return order === 'asc' ? 1 : -1;
          return 0;
        });
        state.sortLayoutInitialized = true;
      }
    },
       rearrangeTradeOrders: (state, action: PayloadAction<string[]>) => {
        const columnOrder = action.payload;
        state.tradeOrders = state.tradeOrders.map(trade => {
          const rearrangedTrade: FinxaiTrade = {} as FinxaiTrade;
          columnOrder.forEach(columnName => {
            if (columnName in trade) {
              rearrangedTrade[columnName] = trade[columnName];
            }
          });
          return rearrangedTrade;
        });
      },



      sortTradeOrders(state, action: PayloadAction<{ field: keyof FinxaiTrade, order: 'asc' | 'desc' }>) {
        const { field, order } = action.payload;
        state.tradeOrders = state.tradeOrders.slice().sort((a, b) => {
          if (a[field] < b[field]) return order === 'asc' ? -1 : 1;
          if (a[field] > b[field]) return order === 'asc' ? 1 : -1;
          return 0;
        });
      },
      sortMarketData(state, action: PayloadAction<{ field: keyof FinxaiMktData, order: 'asc' | 'desc' }>) {
        const { field, order } = action.payload;
        state.marketData = state.marketData.slice().sort((a, b) => {
          if (a[field] < b[field]) return order === 'asc' ? -1 : 1;
          if (a[field] > b[field]) return order === 'asc' ? 1 : -1;
          return 0;
        });
      },

      setMarketData(state, action: PayloadAction<FinxaiMktData[]>) {
        state.marketData = action.payload;
    },

    addOrUpdateStrategy(state, action: PayloadAction<FinxaiStrategy>) {
      const index = state.strategies.findIndex(s => s.instrument.security_id === action.payload.instrument.security_id);
      if (index !== -1) {
          state.strategies[index] = action.payload;
      } else {
          state.strategies.push(action.payload);
      }
      // After updating strategies, reload trade orders to reflect new ticker symbols
      dataSlice.caseReducers.reloadTradeOrders(state);
  },

    addOrUpdateMarketData(state, action: PayloadAction<FinxaiMktData>) {
      const { security_id, bid_px, offer_px } = action.payload;
      const index = state.marketData.findIndex(
        (data) => data.security_id === security_id
      );

      if (index !== -1) {
        const existingData = state.marketData[index];
        // Check if bid or offer price has changed
        if (
          existingData.bid_px !== bid_px ||
          existingData.offer_px !== offer_px
        ) {
          // Update market data
          state.marketData[index] = { ...existingData, ...action.payload };

          // Call the reloadTradeOrders reducer to update trade orders

        
        }
      } else {
        console.log("No market data changed for instrument", security_id)

        const strategy = state.strategies.find(s => 
          {
            return s.instrument.security_id === action.payload.security_id;
          } 
      );

      
      if(strategy)
      {
        // If no existing data, just add new market data
        // only if the order is from Finxai, if not then skip addition.
        state.marketData.unshift(action.payload);
        logger.debug(`Display id for ${action.payload.security_id} is ${strategy.instrument.ticker_symbol}`)
      }
        
      }
      dataSlice.caseReducers.reloadTradeOrders(state);

    },
      setTradeOrders(state, action: PayloadAction<FinxaiTrade[]>) {
        state.tradeOrders = action.payload;
      },
      
      addOrUpdateTradeOrder(state, action: PayloadAction<FinxaiTrade>) {
        const { order_id } = action.payload;
        const index = state.tradeOrders.findIndex(
          (order) => order.order_id === order_id
        );
  
        if (index !== -1) {
          // Replace the object at the found index
          state.tradeOrders[index] = { ...state.tradeOrders[index], ...action.payload };
          
        } else {
          // Insert the new element at the beginning of the array
          state.tradeOrders.unshift(action.payload);
        }
        // Increment tableKey to trigger a table re-render
        state.tableKey += 1;
      },

  


      /// For updating the bid and offer price and also ticker symbol from 
      /// different objects
   reloadTradeOrders(state) {
    state.tradeOrders = state.tradeOrders
        .map((order) => {
            const correspondingMarketData = state.marketData.find(
                (data) => data.security_id === order.security_id
            );

            let updatedOrder = { ...order };

            if (correspondingMarketData) {
                updatedOrder.bid_px = Number(correspondingMarketData.bid_px.toFixed(6));
                updatedOrder.offer_px = Number(correspondingMarketData.offer_px.toFixed(6));
            }

            // Find the corresponding strategy
            const strategy = state.strategies.find(s => 
                s.instrument.security_id === order.security_id 
            );

            if (strategy) {
                if (strategy.instrument.security_id === order.security_id) {
                    updatedOrder.ticker_symbol = strategy.instrument.ticker_symbol;
                } 
            } else {

            
              updatedOrder.ticker_symbol = "N.A";
            }
           

            logger.debug(`Order with OrderID: ${updatedOrder.order_id} has ticker symbol as = ${updatedOrder.ticker_symbol}`)
            return updatedOrder;
        })
      
    state.tableKey += 1;
},

      setTableKey(state, action: PayloadAction<number>) {
        state.tableKey = action.payload
      },
    

      /// For creating grouped layouts of the grid
      setGroups(state, action: PayloadAction<FinxaiDataGroup[]>) {
        state.groups = action.payload;
      },
      addGroup(state, action: PayloadAction<FinxaiDataGroup>) {
        state.groups.unshift(action.payload);
      },
       /// For clearing in memory data on logout
    postLogoutDataCleanup(state) {
      state.marketData = [];
      state.tradeOrders = [];
      state.strategies = [];
      state.sortLayoutInitialized = false;
    }
    },

   
  });


  
  export const {
    postLogoutDataCleanup,
    setTradeOrders,
    rearrangeTradeOrders,
    addOrUpdateTradeOrder,
    addOrUpdateMarketData,
    addOrUpdateStrategy,
    setGroups,
    setTableKey,
    sortTradeOrders,
    sortMarketData,
    addGroup,
    setMarketData,
    reloadTradeOrders,
    initializeDataSort,

  } = dataSlice.actions;
  export default dataSlice.reducer;