import React from "react";
import { CircularProgress, Alert } from "@mui/material";

interface ResearchViewProps {
  url: string;
}

export const ResearchView: React.FC<ResearchViewProps> = ({ url }) => {
  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState<string | null>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  if (!url) {
    return null;
  }

  return (
    <div 
      ref={containerRef} 
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
        minHeight: '500px',
        overflow: 'hidden'
      }}
    >
      {loading && (
        <div 
          style={{ 
            position: "absolute", 
            top: "50%", 
            left: "50%", 
            transform: "translate(-50%, -50%)", 
            zIndex: 10,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: '20px',
            borderRadius: '4px'
          }}
        >
          <CircularProgress />
        </div>
      )}
      
      <iframe
        src={url}
        style={{
          width: "100%",
          height: "100%",
          border: "none",
        }}
        onLoad={() => setLoading(false)}
        onError={(e) => {
          setError("Failed to load content");
          setLoading(false);
        }}
        title="Research Content"
      />
      
      {error && (
        <div 
          style={{ 
            position: "absolute", 
            top: "50%", 
            left: "50%", 
            transform: "translate(-50%, -50%)", 
            zIndex: 10,
            width: '90%',
            maxWidth: '400px'
          }}
        >
          <Alert 
            severity="error"
            style={{
              textAlign: 'center'
            }}
          >
            {error}<br />
            Failed to load: {url}
          </Alert>
        </div>
      )}
    </div>
  );
};