import pino from 'pino';

// Create the Pino logger
const pinoLogger = pino({
  browser: {
    asObject: true,
  },
  level: process.env.NODE_ENV === 'production' ? 'error' : 'debug',
  timestamp: pino.stdTimeFunctions.isoTime,
});

// Create a Winston-like interface
const logger = {
  debug: (message: string, ...args: any[]) => pinoLogger.debug({ ...args }, message),
  info: (message: string, ...args: any[]) => pinoLogger.info({ ...args }, message),
  warn: (message: string, ...args: any[]) => pinoLogger.warn({ ...args }, message),
  error: (message: string, ...args: any[]) => pinoLogger.error({ ...args }, message),
  
  // Add these if you use them in your existing code
  verbose: (message: string, ...args: any[]) => pinoLogger.debug({ ...args }, message),
  silly: (message: string, ...args: any[]) => pinoLogger.trace({ ...args }, message),
  
  // If you need to change log level dynamically
  setLevel: (level: string) => {
    pinoLogger.level = level;
  },
};

export default logger;