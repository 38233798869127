import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UIState {
  isDataFromWsLoading: boolean;
  modalOpen: boolean;
  currentTableIndex: number;
}

const initialState: UIState = {
  isDataFromWsLoading: true,
  modalOpen: false,
  currentTableIndex: 0,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {

    /// for initial order placeholder
    setDataFromWsLoading(state, action: PayloadAction<boolean>) {
      state.isDataFromWsLoading = action.payload;
    },

    /// For handling layout creation popup
    setModalOpen(state, action: PayloadAction<boolean>) {
      state.modalOpen = action.payload;
    },
    setCurrentTableIndex(state, action: PayloadAction<number>) {
      state.currentTableIndex = action.payload;
    },
  },
});

export const { setDataFromWsLoading, setModalOpen, setCurrentTableIndex } = uiSlice.actions;
export default uiSlice.reducer;