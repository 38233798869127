import React, { useCallback, useState } from "react";
import { CustomButton } from "../components/custom-button";
import { useNavigate } from "react-router-dom";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Switch,
  FormControlLabel,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import {
  getWebSocketInstance,
  loginUser,
  setIsLoggingIn,
  handleLoginResponse,
  setLoginError,
  setLoginSuccess,
} from "../store/slices/userSlice";
import useWebSocket from "react-use-websocket";
import { user_login_response } from "../models/protobuff-objects/finxai_user_pb";
import { FinxAiUser } from "../models/finxaiUser";
import { clearAllCache, setCache } from "../utils/cache";
import finxAiLogo from "../assets/logos/finxai_logo_svg_only.svg";
import logger from "../utils/logger";
import { FinxAiFooter } from "../components/finxai-footer";
import { CustomChartComponent, dummyChartData } from "../components/custom-chart";

export const LoginView = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoggingIn, loginError, loggedInUser } = useSelector(
    (state: RootState) => state.user
  );
  const [showDisclaimer, setShowDisclaimer] = useState(false);
  const [username, setUsernameState] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isQa, setIsQa] = useState<boolean>(false);
  const navigate = useNavigate();
  const loginBrandHeader = process.env.REACT_APP_LOGIN_TEXT || "";
  const disclaimerShort = process.env.REACT_APP_DISCLAIMER_SHORT || "";
  const disclaimerLongFilePath =
    process.env.REACT_APP_DISCLAIMER_LONG_FILE_PATH || "";

  // Handle dialog open/close
  const openDisclaimer = () => setShowDisclaimer(true);
  const closeDisclaimer = () => setShowDisclaimer(false);

  // Create a WebSocket connection
  const { sendMessage, lastMessage, readyState } = useWebSocket(
    process.env.REACT_APP_WEB_SOCKET_ENDPOINT as string,
    {
      share: true, /// This will share the WebSocket instance across different components
      onOpen: (event) => {
        logger.debug("Connected to the Grid WebSocket");
      },

      onMessage: async (event) => {
        logger.debug("Received message from WebSocket LOGINVIEW");
        const ws = getWebSocketInstance();
        const response = await ws.handleBinaryMessage(event.data);

        if (response instanceof user_login_response) {
          if (response.getIsLoggedin()) {
            logger.debug("Loggin successful, navigating.");
            dispatch(handleLoginResponse(response));
            /// build and hold the user object in user store
            const currentUser = ws.builUserObject(response, username, password);
            cacheUser(currentUser);
            dispatch(setLoginSuccess(currentUser));
            navigate("/home");
          } else {
            dispatch(
              setLoginError("Username or Password Incorrect. Login Failed.")
            );
          }
        }
      },
    }
  );

  const cacheUser = (user: FinxAiUser) => {
    /// Cache the user for a week in browser cache
    setCache("loggedInUser", user, 7);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setUsernameState(e.target.value);
  };

  const handlePasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setPassword(e.target.value);
  };

  const handleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setIsQa(e.target.checked);
  };

  const handleLogin = useCallback(async () => {
    if (username && password) {
      try {
        const loginRequestMessage = await dispatch(
          loginUser({
            username: username,
            password: password,
            isQa: isQa.toString(),
          })
        );

        // Send the login request message via WebSocket
        sendMessage(loginRequestMessage.serializeBinary());
      } catch (error) {
        console.error("Login failed:", error);
        dispatch(setIsLoggingIn(false));
      }
    }
  }, [username, password, isQa, dispatch, sendMessage]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();
    handleLogin();
  };

  const isFormValid = username !== "" && password !== "";

  return (
    <div className="justify-center items-center flex flex-col h-screen">
      <h1 className="text-3xl font-bold pb-3 text-white">{loginBrandHeader}</h1>
      <div className="logincard min-w-72 bg-primaryTableBg justify-center items-center flex flex-col p-5 shadow-xl rounded-xl">
        <div className="justify-center items-center flex flex-row">
          {/* <img
            className="w-10 h-10 mb-3 mx-1"
            src={finxAiLogo}
            alt="FinxAi Logo"
          /> */}
          <h1 className="text-2xl font-semibold pb-3 text-gray-300">Login</h1>
        </div>
        <form className="flex flex-col w-full" onSubmit={handleSubmit}>
          <input
            className="username text-sm bg-inputFieldBg rounded-xl p-2 mb-3 text-white"
            type="text"
            placeholder="Username"
            onChange={handleEmailChange}
            value={username}
          />
          <div className="relative">
            <input
              className="password text-sm bg-inputFieldBg rounded-xl p-2 text-white w-full"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
            />
            <button
              type="button"
              className="scale-75 absolute right-2 top-1 text-cellTextFontColor text-sm"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </button>
          </div>
          {/* <FormControlLabel
            control={
              <Switch
                checked={isQa}
                onChange={handleSwitchChange}
                color="secondary"
              />
            }
            label="QA"
            className="mt-2 text-white items-center self-center "
          /> */}
          <div
            className={`transition-opacity duration-300 ease-in-out ${
              isFormValid ? "opacity-100 mt-2" : "opacity-50"
            }`}
          >
            {isLoggingIn ? (
              <CircularProgress color="secondary" />
            ) : (
              <CustomButton
                className={`${!isFormValid ? "opacity-50" : ""}`}
                label="Login"
                onClick={() => {}}
              />
            )}
          </div>
        </form>
        {loginError && ( // Display error message if loginError is not null
          <div className="mt-4 text-red-500 text-sm">{loginError}</div>
        )}
      </div>

      <div className="text-gray-300 text-sm mt-4 text-center">
        {disclaimerShort}
        {disclaimerLongFilePath && (
          <span>
            &nbsp;
            <button
              onClick={openDisclaimer}
              className="text-blue-500 underline"
            >
              For more, please click here
            </button>
          </span>
        )}
      </div>

      {/* Disclaimer Dialog */}
      <Dialog
  open={showDisclaimer}
  onClose={closeDisclaimer}
  sx={{
    "& .MuiPaper-root": {
      borderRadius: "15px",
      backgroundColor: "#27406A",
      boxShadow: 5, // Adds elevation
    },
  }}
>
  <DialogTitle style={{ color: "#FFFFFF" }}>Disclaimer</DialogTitle>
  <DialogContent>
    <iframe
      src={disclaimerLongFilePath}
      title="Disclaimer"
      style={{ width: "100%", height: "500px", borderRadius: "10px" }}
    />
  </DialogContent>
</Dialog>
<div
>

</div>
      <FinxAiFooter />
    </div>
  );
};
